import React, { useContext } from "react";
import { ReactComponent as LogoSVG } from "./../../../icons/logo-ebbiner.svg";
import { ReactComponent as DoorCloseSVG } from "./../../../icons/door-close.svg";
import { ReactComponent as ExamsSVG } from "./../../../icons/exams.svg";
import { Link } from "react-router-dom";
import { NavbarHomeDContainer, UnlistNavbarHomeD } from "./styles/sNavbarHomeD";
import { listItemsNavbar } from "./listItemsNavbar";
import ItemNavbarHomeD from "./ItemNavbarHomeD";
import { AppContext } from "../../../App";
import { roles } from "../../../constants/roles";

export default function NavbarHomeD() {
  const { dataOfUser } = useContext(AppContext);
  const lastListItemsNavbar = [
    {
      nameItem: "Cerrar sesión",
      icon: <DoorCloseSVG />,
      link: "/",
      item: "signOut",
    },
  ];

  // si el usuario es de marketing, se agrega el item de datos de usuarios
  dataOfUser.typeOfUser.includes(roles.MARKETING) &&
    !listItemsNavbar.find((item) => item.nameItem === "Datos de usuarios") &&
    listItemsNavbar.push({
      nameItem: "Datos de usuarios",
      icon: <ExamsSVG />,
      link: "/data-students",
    });

  return (
    <NavbarHomeDContainer>
      <div className="logoNavbarAHomeD">
        <Link to="/home" aria-label="Logo Ebbiner">
          <LogoSVG />
        </Link>
      </div>
      <UnlistNavbarHomeD>
        {listItemsNavbar.map((item) => (
          <ItemNavbarHomeD key={item.nameItem} {...item} />
        ))}
      </UnlistNavbarHomeD>
      <UnlistNavbarHomeD>
        {lastListItemsNavbar.map((item) => (
          <ItemNavbarHomeD key={item.nameItem} {...item} />
        ))}
      </UnlistNavbarHomeD>
    </NavbarHomeDContainer>
  );
}
