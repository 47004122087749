import { doc, updateDoc } from "firebase/firestore";
export const updateUserEndTimestamp = async ({
  db,
  uid,
  endTimestamp,
  nameDb = "users",
}) => {
  try {
    await updateDoc(doc(db, nameDb, uid), {
      "subscriptionData.endTimestamp": endTimestamp,
    });
    return true;
  } catch (error) {
    console.error("Error al actualizar el usuario:", error);
    throw error;
  }
};
