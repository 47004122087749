import React from "react";
import { useTranslation } from "react-i18next";
import { TagContainer } from "./styles/sTag";
import { ReactComponent as CloseSVG } from './../../../icons/close.svg'

export default function Tag({ name, type, onDelete }) {
  const [t] = useTranslation("main");
  return (
    <li>
      <TagContainer type={type} name={name}>
        <p>
          {
            type === "course" ? t(`courses.${name}`) :  name
          }
        </p>
        <CloseSVG onClick={() => onDelete(name)} />
      </TagContainer>
    </li>
  );
}
