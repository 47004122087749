import { Latex } from "../../latex/Latex";
import {
  LatexAlternativesContainer,
  LatexQuestionContainer,
  LatexSolutionContainer,
  QuestionPreviewCOntainer,
} from "./styles/sQuestionPreview";

export const QuestionPreview = ({ question, alternatives }) => {
  return (
    <QuestionPreviewCOntainer>
      <h2 className="title-prev-question">Vista previa</h2>
      <label className="rules">
        <span>Importante:</span>
        <i>No superar los limites considerados!!!</i>
      </label>
      <hr />
      <LatexQuestionContainer>
        <span className="text-latex">
          <Latex withBorder inPreview>
            {question.question?.text
              ? question.question?.text.replaceAll(" ", "\\space ")
              : "\\blacktriangleright"}
          </Latex>
        </span>
        {question.question.image && (
          <img
            className="image-question"
            src={URL.createObjectURL(question.question.image)}
            alt="question"
          />
        )}
      </LatexQuestionContainer>
      <br />
      <LatexAlternativesContainer>
        {alternatives.map((alt, index) => (
          <div key={index}>
            <Latex withBorder inPreview>{`
                      ${
                        index + 1 === 1
                          ? `\\textcolor{green}{${index + 1})}`
                          : `\\textcolor{red}{${index + 1})}`
                      } \\space 
                      ${
                        alt.alternative?.text
                          ? index + 1 === 1
                            ? `\\textcolor{green}{${alt.alternative?.text.replaceAll(
                                " ",
                                "\\space "
                              )}}`
                            : `\\textcolor{red}{${alt.alternative?.text.replaceAll(
                                " ",
                                "\\space "
                              )}}`
                          : ""
                      }
                      `}</Latex>
          </div>
        ))}
      </LatexAlternativesContainer>
      <LatexSolutionContainer>
        <h3 className="title-solution">Solución: </h3>
        <Latex withBorder inPreview>
          {question.solution?.textSolution?.replaceAll(" ", "\\space ") ?? ""}
        </Latex>
        {question.solution.imageSolution && (
          <img
            className="image-solution"
            src={URL.createObjectURL(question.solution.imageSolution)}
            alt="solution"
          />
        )}
      </LatexSolutionContainer>
    </QuestionPreviewCOntainer>
  );
};
// 0.85 em
