import React from "react";
import { Helmet } from "react-helmet";
import { MainSubscription } from "../components/subscriptions/editSubscriton/MainSubscription";

export const Subscriptions = () => {
  return (
    <>
      <Helmet>
        <title>Subscription data | Ebbiner</title>
      </Helmet>
      <MainSubscription />
    </>
  );
};
