import html2canvas from "html2canvas";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { Buffer } from "buffer";

export const takeCaptureToQuestion = async ({
  captureRef,
  uqid,
  course,
  keyLetter,
}) => {
  const canvas = await html2canvas(captureRef);
  const imageData = canvas.toDataURL("image/jpeg").split(",")[1];
  const buffer = new Buffer(imageData, "base64");
  let imageRenamed = new File([buffer], `capture_${keyLetter}${uqid}.png`, {
    type: "image/png",
  });
  const storage = getStorage();
  const routePath = `questionImages/${course}/${uqid}/${imageRenamed.name}`;
  const storageRef = ref(storage, routePath);
  const uploadTask = uploadBytesResumable(storageRef, imageRenamed);
  uploadTask.on("state_changed", (snapshot) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log("progress", progress);
  });
  return await uploadTask.then(async (result) => {
    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
    if (result.state === "success" && downloadURL) {
      return {
        urlImage: downloadURL,
        path: result.metadata.fullPath,
      };
    } else {
      return { error: "Error al subir la captura" };
    }
  });
};
