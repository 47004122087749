import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Title4, Title5 } from "./../../styles/textGeneral";
import { WrapperAdmin } from "./../../styles/generalStyles";
import { ContentDetailsQuestion } from "./ContentDetailsQuestion";
import { AppContext } from "../../App";
import { InputContainer } from "../../styles/inputGeneral";
// import { useTranslation } from 'react-i18next';
import { filterForCourse } from "./algorithms/filterForCourse";

export default function MainHome() {
  // const [t] = useTranslation('main');
  const {
    unreviewedQuestionData,
    dataOfAuthors,
    dataOfUser,
    currentUser,
    listOfCourses,
  } = useContext(AppContext);
  const navigate = useNavigate();

  const [dataSortedByCourse, setDataSortedByCourse] = useState([]);
  const [courseToFilter, setCourseToFilter] = useState("all");

  //ordena el los cursos en orden alfabetico
  const listOfCoursesNames = listOfCourses
      ?.map((course) => course.value)
      .sort((a, b) => a.localeCompare(b));

  useEffect(() => {
    !currentUser && navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Efecto de filtro por curso
  useEffect(() => {
    filterForCourse({
      courseToFilter,
      setDataSortedByCourse,
      listOfCourses,
      unreviewedQuestionData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseToFilter, unreviewedQuestionData, listOfCourses]);

  return (
      <main>
        <WrapperAdmin>
          <div>
            <Title4>{`Hola ${dataOfUser?.username}`}</Title4>
          </div>
          <div>
            <Title5>Filtrar por curso:</Title5>
          </div>
          <div className="input-select">
            <InputContainer margin10B>
              <label>Curso *</label>
              <div className="select">
                <select
                    id="standard-select"
                    onChange={(e) => setCourseToFilter(e.target.value)}
                >
                  <option value={"all"}>Todos los cursos</option>
                  {listOfCoursesNames?.map((courses, index) => (
                      <option key={index} value={courses}>
                        {courses}
                      </option>
                  ))}
                </select>
                <span className="focus"></span>
              </div>
            </InputContainer>
          </div>
          <div>
            <Title5>Preguntas para revisar</Title5>
          </div>
          {unreviewedQuestionData ? (
              dataSortedByCourse?.map((question, index) => {
                return (
                    <div key={index}>
                      <ContentDetailsQuestion
                          key={question.uqid}
                          uqid={question.uqid}
                          topic={question.latexQuestion}
                          authorId={question.authorId}
                          date={question.dateUpload}
                          universities={question.university}
                          dataOfAuthors={dataOfAuthors}
                          course={question.course}
                      />
                    </div>
                );
              })
          ) : (
              <div>No hay preguntas para revisar</div>
          )}
        </WrapperAdmin>
      </main>
  );
}
