import styled from "styled-components";

export const QuestionAndKeysContainer = styled.div`
  max-width: 700px;
  padding: 25px 50px 0 50px;
  border-radius: 14px;
  border: 4px solid rgba(128, 77, 231, 0.5);
`;

export const TitleOfQuestion = styled.h1`
  color: #297ded;
`;

export const LayoutQuestion = styled.div`
  // background-color: red;
  display: grid;
  grid-template-columns: 1fr 300px;
`;
