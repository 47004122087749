import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { doc, onSnapshot } from "firebase/firestore";
import { useFirestore } from "reactfire";

export default function Counter() {
  const db = useFirestore();
  const [counters, setCounters] = useState(null);
  useEffect(() => {
    const docRef = doc(db, "indices", "counters");
    const unsubscribe = onSnapshot(docRef, (doc) => {
      setCounters(doc.data());
    });
    return () => unsubscribe();
  }, []);
  return (
    <>
      <Helmet>
        <title>Counter | Ebbiner</title>
      </Helmet>
      <div style={{ margin: "auto", fontSize: "7em", textAlign: "center" }}>
        <h1>{counters?.chatsKat ?? "Cargando..."}</h1>
        <h4>Chats totales</h4>
      </div>
      <div style={{ margin: "auto", fontSize: "7em", textAlign: "center" }}>
        <h1>{counters?.botUsers ?? "Cargando..."}</h1>
        <h4>Usuarios de Kat</h4>
      </div>
    </>
  );
}
