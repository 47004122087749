import { colors, fonts } from "./fundations";

const theme = () => {
  return {
    /* Colors */
    brandColor: colors.color.brand,
    textColor: colors.color.black.b500,
    whiteColor: colors.color.white.full,
    backgroundColor: colors.color.white.background,
    white1500: colors.color.white.w1500,
    white1600: colors.color.white.w1600,
    white1700: colors.color.white.w1700,
    purple100: colors.color.purple.p100,
    purple200: colors.color.purple.p200,
    purple300: colors.color.purple.p300,
    purple500: colors.color.purple.p500,
    purple700: colors.color.purple.p700,
    pink500: colors.color.pink.p500,
    pink700: colors.color.pink.p700,
    pink2000: colors.color.pink.p2000,
    green300: colors.color.green.g300,
    green500: colors.color.green.g500,
    green700: colors.color.green.g700,
    green1000: colors.color.green.g1000,
    gray000: colors.color.gray.g000,
    gray100: colors.color.gray.g100,
    gray200: colors.color.gray.g200,
    gray300: colors.color.gray.g300,
    gray400: colors.color.gray.g400,
    gray500: colors.color.gray.g500,
    gray1100: colors.color.gray.g1100,
    gray1200: colors.color.gray.g1200,
    gray1400: colors.color.gray.g1400,
    gray1500: colors.color.gray.g1500,
    gray1600: colors.color.gray.g1600,
    gray1700: colors.color.gray.g1700,
    gray1800: colors.color.gray.g1800,
    gray1900: colors.color.gray.g1900,
    yellow200: colors.color.yellow.y200,
    yellow300: colors.color.yellow.y300,
    yellow500: colors.color.yellow.y500,
    yellow700: colors.color.yellow.y700,
    blue100: colors.color.blue.b100,
    blue200: colors.color.blue.b200,
    blue300: colors.color.blue.b300,
    blue700: colors.color.blue.b700,
    blue1500: colors.color.blue.b1500,
    red100: colors.color.red.r100,
    red300: colors.color.red.r300,
    red500: colors.color.red.r500,
    red700: colors.color.red.r700,
    degradeBrand: colors.color.degrade.brand,
    shadowCard: colors.shadow.card,
    shadowCardImage: colors.color.black.b100,
    ebbiner: colors.color.ebbiner.ebbi,

    /* Fonts */
    brandFont: fonts.brandFont,
    titleFont: fonts.titleFont,
    textFont: fonts.textFont,
    weight: fonts.weight,
  };
};

export default theme;
