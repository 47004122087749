import React from 'react';

import { collection, query, where, getDocs } from 'firebase/firestore';
export const recoverDataReportedQuestions = async ({
  firestoreInstance,
  setReportedQuestionData,
  setLoading,
}) => {
  setLoading({ status: true, title: 'Recuperando preguntas reportadas ...' });
  let questionDocs = await getDocs(
    query(collection(firestoreInstance, 'questions'), where('isReported', '==', true)),
  );
  let questions = [];
  questionDocs.docs.map((doc) => {
    questions.push(doc.data());
  });
  setReportedQuestionData(questions);
  setLoading({ status: false, title: null });
};
