import katex from 'katex';
import { LatexContainer, LatexTextContainer } from './style/sLatex';

export const Latex = ({ children, withBorder = null, inPreview = null }) => {
  return (
    <LatexContainer withBorder={withBorder}>
      <LatexTextContainer
        id="textLatex"
        inPreview={inPreview}
        dangerouslySetInnerHTML={{
          __html: katex.renderToString(children, {
            throwOnError: false,
          }),
        }}
      ></LatexTextContainer>
    </LatexContainer>
  );
};
