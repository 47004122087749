import styled from 'styled-components';

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;

  button {
    width: 50%;
  }

  p {
    display: flex;
    justify-content: center;
    margin: 0;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 1.2rem;
    font-family: ${(props) => props.theme.textFont};

    span {
      font-weight: 400;
      margin-left: 5px;
    }
  }
  .container-input-reset {
    display: grid;
    grid-template-columns: 1fr 100px;
    align-items: center;
    grid-gap: 10px;

    button {
      width: 100%;
    }
  }
`;
