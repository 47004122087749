import styled from 'styled-components';

export const ReportTextContainer = styled.div`
  height: auto;
  border-radius: 5px;
  font-size: 1.2rem;

  textarea:read-only {
    background: ${props => props.theme.red300};
    border: none;
    color: ${props => props.theme.red500};
    font-size: 15px;
  }
`;

export const ButtonsLatexUnlist = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;
