import Helmet from 'react-helmet';
import { MainReportedQuestion } from '../components/examns/editQuestion/MainReportedQuestion';

export default function EditQuestion() {
  return (
    <>
      <Helmet>
        <title>Editar pregunta | Ebbiner</title>
      </Helmet>
      <MainReportedQuestion />
    </>
  );
}
