import { arrayUnion, collection, doc, setDoc, writeBatch } from 'firebase/firestore';

export const sendDownloadLogs = async ({ db, dataOfUser, dataRequiredSelectedName }) => {
  try {
    const timestamp = new Date();
    const data = {
      user: dataOfUser.email,
      uid: dataOfUser.uid,
      username: dataOfUser.username,
      downloadDetaill: arrayUnion({
        typeOfData: dataRequiredSelectedName,
        date: timestamp,
      }),
    };
    const batch = await writeBatch(db);
    const downloadRef = doc(collection(db, 'downloadLogs'), dataOfUser.uid);

    setDoc(downloadRef, data, { merge: true });
    await batch.commit().then(() => {
      // console.log('Download logs saved');
    });
  } catch (err) {
    throw new Error(err.message);
  }
};
