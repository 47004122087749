import styled from "styled-components";

export const QuestionPreviewCOntainer = styled.div`
  background-color: ${(props) => props.theme.whiteColor};
  border-radius: 10px;
  padding: 10px;
  width: 100%;

  .image-question {
    width: 300px;
    margin-top: 10px;
  }

  .image-solution {
    width: 300px;
    margin-top: 10px;
  }
  .text-latex {
    font-size: 0.85em;
    line-height: 1.5em;
  }

  .rules {
    font-size: 0.8em;
    font-weight: 600;
    color: ${(props) => props.theme.gray1500};
    span {
      color: ${props => props.theme.red500};
    }
  }

  .title-prev-question {
    color: ${(props) => props.theme.gray1500};
    margin-bottom: 10px;
  }
`;
export const LatexQuestionContainer = styled.div`
  .color-red {
    color: ${props => props.theme.red500};
  }
`;

export const LatexAlternativesContainer = styled.ul`
  font-size: 0.85em !important;
  line-height: 1.5em !important;

  li {
    &:first-child {
      margin: 0;
    }
  }
`;
export const InRow = styled.li`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 5px 0 0 0;
  align-items: center;
`;

export const BoxAlternative = styled.div`
  padding: 8px;
  align-items: center;
  border-color: #804de7;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  color: #161e24;
  display: flex;
  justify-content: center;
  text-align: left;
`;
export const LatexSolutionContainer = styled.div`
  font-size: 0.85em !important;
  line-height: 1.5em !important;

  .title-solution {
    color: ${(props) => props.theme.gray1500};
    margin: 10px 0;
  }
`;
