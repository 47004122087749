import styled from "styled-components";

export const SearchContainerSubscriptions = styled.div`
  form {
    display: grid;
    grid-template-columns: 1fr 200px;
    gap: 1rem;
    align-items: center;
  }
`;
