import React, { useState } from "react";
import { Button } from "../../../../styles/buttonGeneral";
import { InputContainer } from "../../../../styles/inputGeneral";
import { SearchContainerSubscriptions } from "./sMainSubscription";
import { useForm } from "react-hook-form";
import { ErrorText } from "../../../../styles/textGeneral";
import { yupResolver } from "@hookform/resolvers/yup";
import { formMainSearch } from "../../addSucriptor/validator/addSubscriptorFormValidatir";

export const MainSearchUser = ({
  onSearchUser,
  emailForSearch,
  setEmailForSearch,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formMainSearch),
  });
  const onHandleSubmit = (data) => {
    onSearchUser({ emailForSearch });
  };
  return (
    <SearchContainerSubscriptions>
      <form onSubmit={handleSubmit(onHandleSubmit)}>
        <InputContainer>
          <label>Buscar usuario por correo:</label>
          <input
            type="email"
            placeholder="Correo electrónico: ejemplo@gmail.com"
            {...register("email")}
            onBlur={(e) => setEmailForSearch(e.target.value)}
            required
          />
          {errors.email && <ErrorText>{errors?.email?.message}</ErrorText>}
        </InputContainer>
        <Button primary type="submit">
          Buscar
        </Button>
      </form>
    </SearchContainerSubscriptions>
  );
};
