import React, { useContext, useState, useEffect, useRef } from 'react';
import { AppContext } from '../../App';
import { Button } from '../../styles/buttonGeneral';
import { QuestionAndKeysContainer } from '../examns/checkQuestion/styles/sMainCheckQuestion';
import { sendQuestionForQuestionBank } from '../examns/checkQuestion/algorithms/sendQuestionForQuestionBank';
import { UploadStatusRevisedQuestion } from '../examns/checkQuestion/algorithms/updateStatusQuestion';
import { ReactComponent as CloseSVG } from './../../icons/close.svg';
import { Overlay, ModalContainer } from './styles/sMainModal';
import QuestionOutput from '../examns/checkQuestion/QuestionOutput';

//Import ALGORITMS
import { takeCaptureToQuestion } from '../examns/checkQuestion/algorithms/takeCaptureToQuestion';

export function MainModalCheck({
  db,
  uqid,
  navigate,
  modalState,
  setModalState,
  title,
  subTopicId,
  setResultOfQuestion,
  dataForQuestionBank,
  captureData = null,
}) {
  const {
    setLoading,
    setDataOfQuestionToReview,
    setUnreviewedQuestionData,
    unreviewedQuestionData,
    dataOfUser,
  } = useContext(AppContext);
  const questionRef = useRef(null);
  const [keyLetter, setKeyLetter] = useState(null);

  const launchTakeCapture = async () => {
    const objectCapture = await takeCaptureToQuestion({
      captureRef: questionRef?.current,
      uqid,
      course: captureData.course,
      keyLetter,
    });

    return objectCapture;
  };

  useEffect(() => {}, [keyLetter]);

  return (
    <>
      {modalState && (
        <div>
          <Overlay
            onClick={() => {
              setModalState(false);
            }}
          ></Overlay>
          <ModalContainer>
            <div>
              {captureData && (
                <h1>{'Esta imagen se enviará para el bot de WhatsApp, ¿es correcta?'}</h1>
              )}
              <CloseSVG
                className="closeModal"
                onClick={() => {
                  setModalState(false);
                }}
              />
            </div>
            <QuestionAndKeysContainer ref={questionRef}>
              {captureData && (
                <QuestionOutput
                  course={captureData.course}
                  setKeyLetter={setKeyLetter}
                  question={captureData?.question}
                  keys={captureData?.keys}
                  UrlOfImage={captureData?.urlOfImage}
                  captureQuestion={true}
                />
              )}
            </QuestionAndKeysContainer>
            <div>
              <h1>{title}</h1>
            </div>
            <div className="buttonsContent">
              <Button
                secondary
                onClick={(e) => {
                  e.preventDefault();
                  setModalState(false);
                }}
              >
                {'No'}
              </Button>
              <Button
                primary
                onClick={() => {
                  sendQuestionForQuestionBank({
                    db,
                    dataForQuestionBank,
                    setLoading,
                  });
                  subTopicId &&
                    UploadStatusRevisedQuestion({
                      db,
                      uqid,
                      setLoading,
                      navigate,
                      setUnreviewedQuestionData,
                      unreviewedQuestionData,
                      dataOfUser,
                      subTopicId,
                      launchTakeCapture,
                    });
                  setDataOfQuestionToReview(null);
                  setResultOfQuestion(null);
                  localStorage.setItem('idQuestion', '');
                  setModalState(!modalState);
                }}
              >
                {'Si, esta correcto'}
              </Button>
            </div>
          </ModalContainer>
        </div>
      )}
    </>
  );
}
