// @ts-nocheck
import React, { useEffect, useState } from "react";
import { WrapperAdmin } from "../../../styles/generalStyles";
import { ErrorText, Title2 } from "../../../styles/textGeneral";
import { InputContainer } from "../../../styles/inputGeneral";
import { AddSubscriberContainer } from "./styles/sMainAddSubscriber";
import { Button } from "../../../styles/buttonGeneral";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { formAddSubscriber } from "./validator/addSubscriptorFormValidatir";
import { recoverUserForEmail } from "../algorithm/recoverUserForEmail";
import { useFirestore } from "reactfire";
import { createNewSubscriber } from "../algorithm/createNewSubscriber";

export const MainAddSubscriber = ({
  emailForSearch,
  setErrorState,
  setSuccessState,
}) => {
  const [userExist, setUserExist] = useState(false);
  const db = useFirestore();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formAddSubscriber),
    defaultValues: {
      email: emailForSearch,
    },
  });

  const onHandleSubmit = async (data) => {
    const { email, username, dateLimit } = data;
    const dataForSubmit = {
      email,
      username,
      subscriptionData: {
        endTimestamp: new Date(dateLimit),
      },
    };
    await createNewSubscriber({ db, dataNewuser: dataForSubmit });
    setErrorState(null);
    setSuccessState(true);
  };

  return (
    <main>
      <WrapperAdmin>
        <Title2>🤙 Agreguemos el nuevo usuario</Title2>
        <ErrorText>Verifica los datos antes de enviar!</ErrorText>
        <AddSubscriberContainer>
          <form onSubmit={handleSubmit(onHandleSubmit)}>
            <InputContainer>
              <label>Correo electrónico:</label>
              <input
                type="email"
                placeholder="Correo electrónico:"
                required
                {...register("email")}
                onFocus={() => setUserExist(false)}
                onBlur={async () => {
                  clearErrors();
                  const user = await recoverUserForEmail({
                    db,
                    emailForSearch: getValues("email"),
                  });
                  setUserExist(!!user);
                }}
              />
              {(errors?.email || userExist) && (
                <ErrorText>
                  {errors?.email?.message || "El usuario ya existe!"}
                </ErrorText>
              )}
            </InputContainer>
            <InputContainer>
              <label>Nombre completo:</label>
              <input
                type="text"
                disabled={userExist}
                placeholder="Nombre completo:"
                required
                {...register("username")}
              />
              {errors.username && (
                <ErrorText>{errors.username.message}</ErrorText>
              )}
            </InputContainer>
            <InputContainer>
              <label>Fecha de expiración:</label>
              <input
                type="date"
                disabled={userExist}
                required
                {...register("dateLimit")}
              />
              {errors.dateLimit && (
                <ErrorText>{errors.dateLimit.message}</ErrorText>
              )}
            </InputContainer>
            {!userExist && (
              <Button primary type="submit">
                Crear usuario
              </Button>
            )}
          </form>
        </AddSubscriberContainer>
      </WrapperAdmin>
    </main>
  );
};
