import styled from "styled-components";

export const AddSubscriberContainer = styled.div`
  form {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    input {
      display: flex;
      width: 100%;
      min-width: 310px;
      padding: 8px;
      border-radius: 4px;
      border: 1px solid #ccc;
    }

    button {
      margin-top: 16px;
      max-width: 250px;
    }
  }
`;
