import { SpinnerContainer, SpinnerWheel } from './styles/sSpinner';

export const Spinner = ({ title, height }) => {
  return (
    <SpinnerContainer height={height}>
      <SpinnerWheel />
      {title && <p>{title}</p>}
    </SpinnerContainer>
  );
};

export default Spinner;
