import { collection, doc, setDoc } from "firebase/firestore";

export const createNewSubscriber = async ({ db, dataNewuser }) => {
  try {
    const userId = crypto.randomUUID();
    const refCollection = collection(db, "usersRegistered");
    const docRef = doc(refCollection, userId);

    await setDoc(
      docRef,
      { ...dataNewuser, uid: userId, isTemporal: true },
      { merge: true }
    );
    return docRef.id;
  } catch (error) {
    console.error("Error al crear nuevo usuario:", error);
    throw error;
  }
};
