import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { AppContext } from "../../../App";
import { FirestoreSdkContext } from "reactfire";
import "katex/dist/katex.min.css";
import { Latex } from "../../latex/Latex";

// IMPORT COMPONENTS AND STYLES
import { WrapperAdmin } from "../../../styles/generalStyles";
import { Title4, Title5, Title6 } from "../../../styles/textGeneral";
import { FormContainer, InputContainer } from "../../../styles/inputGeneral";
import Tag from "../../general/cOthers/Tag";
import { ErrorText } from "../writeQuestion/styles/sErrorText";
import { WrapperDuplex } from "../../../styles/boxesGeneral";
import InputSvg from "../../general/cOthers/InputSvg";
import { ButtonLatex } from "../writeQuestion/styles/sButtonLatex";
import { Button } from "../../../styles/buttonGeneral";
import { MainModalUpload } from "../../modal/MainModalUpload";
import { QuestionPreview } from "../writeQuestion/QuestionPreview";
import Mainspinner from "../../spinner/MainSpinner";

//IMPORTS FUNCTIONS

import { functionLatex } from "../functionsLatex/functionsLatex";
import { optionDefaults } from "../../../constants/generalConstants";
import {
  requeridValidator,
  typeQuestionValidator,
  urlVideoFacebookValidatorNR,
  urlVideoYoutubeValidatorNR,
} from "../writeQuestion/validators/formValidators";
import { generatorYear } from "../writeQuestion/algorithms/generatorYear";
import {
  ButtonsLatexUnlist,
  ReportTextContainer,
} from "./styles/sEditQuestion";
import {
  filterTopics,
  handleClickFunction,
  onChangeStateModal,
  resetValues,
} from "../writeQuestion/functions";
import { recoveryDataSubTopics } from "../writeQuestion/algorithms/recoveryDataSubtopics";
import { searchSolutionQuestion } from "../checkQuestion/algorithms/searchSolutionQuestion";
import { onSubmitDataQuestion } from "../writeQuestion/algorithms/onSubmitDataQuestion";

export const EditQuestionReported = () => {
  //CONTEXT AND ROUTES
  const navigate = useNavigate();
  const { idQuestion } = useParams();
  const {
    universities,
    dataSubTopics,
    setDataSubTopics,
    listOfCourses,
    dataOfUser,
    reportedQuestionData = [],
    setReportedQuestionData,
  } = useContext(AppContext);
  const db = useContext(FirestoreSdkContext);
  // console.log(dataSubTopics);

  //STATES
  const [dataQuestion, setDataQuestion] = useState(
      reportedQuestionData.filter((q) => q.uqid === idQuestion)[0] ?? null
  );
  const [loading, setLoading] = useState({ status: false, title: null });
  const [modalState, setModalState] = useState(false);
  const [universitiesSelected, setUniversitiesSelected] = useState(
      dataQuestion?.university ?? []
  );
  const [courseSelected, setCourseSelected] = useState(
      listOfCourses.filter((c) => {
        return c.name === dataQuestion?.course;
      })[0].value ?? null
  );
  const [topicsFilters, setTopicsFilters] = useState([]);

  //TODO: Evaluar y refactorizar
  const [topicSelected, setTopicSelected] = useState(null);
  const [subTopicSelectedName, setSubTopicSelectedName] = useState(null);
  // console.log(topicSelected);
  const [subTopicSelected, setSubTopicSelected] = useState(null);
  const [resultOfQuestion, setResultOfQuestion] = useState(null);
  const [selectionCategory, setSelectionCategory] = useState("");
  const [superiorSelections, setSuperiorSelections] = useState({
    selections: { start: 0, end: 0 },
    setSelections: null,
    setInferiorText: null,
  });

  //useForm
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      isPreUniversityCheck: dataQuestion?.isQuestionOfPreUniversity ?? false,
      typeQuestion: dataQuestion?.typeQuestion ?? null,
      urlVideoYoutube: resultOfQuestion
          ? resultOfQuestion[0]?.urlOfVideo?.youtube
          : "",
      urlVideoFacebook: resultOfQuestion
          ? resultOfQuestion[0]?.urlOfVideo?.facebook
          : "",
    },
  });

  const [question, setQuestion] = useState({
    question: {
      image: null,
      text: dataQuestion?.latexQuestion ?? null,
      plainText: dataQuestion?.SEOQuestion ?? null,
      urlImage: null,
    },
    solution: {
      imageSolution: null,
      urlImageSolution: null,
      textSolution: null,
      plainTextSolution: null,
      urlVideoYoutube: null,
      urlVideoFacebook: null,
    },
  });

  const [alternatives, setAlternatives] = useState([
    {
      alternativeId: 1,
      alternative: {
        image: null,
        text: dataQuestion?.keys[0]?.key ?? null,
        plainText: dataQuestion?.SEOAlternatives[0]?.key ?? null,
      },
    },
    {
      alternativeId: 2,
      alternative: {
        image: null,
        text: dataQuestion?.keys[1]?.key ?? null,
        plainText: dataQuestion?.SEOAlternatives[1]?.key ?? null,
      },
    },
    {
      alternativeId: 3,
      alternative: {
        image: null,
        text: dataQuestion?.keys[2]?.key ?? null,
        plainText: dataQuestion?.SEOAlternatives[2]?.key ?? null,
      },
    },
    {
      alternativeId: 4,
      alternative: {
        image: null,
        text: dataQuestion?.keys[3]?.key ?? null,
        plainText: dataQuestion?.SEOAlternatives[3]?.key ?? null,
      },
    },
    {
      alternativeId: 5,
      alternative: {
        image: null,
        text: dataQuestion?.keys[4]?.key ?? null,
        plainText: dataQuestion?.SEOAlternatives[4]?.key ?? null,
      },
    },
  ]);

  const listOfCoursesNames = listOfCourses
      ?.map((course) => course.value)
      .sort((a, b) => a.localeCompare(b));

  const courseSelectedName = listOfCourses?.find(
      (c) => c.value === courseSelected
  )?.name;

  const onTagDeleteU = (tagName) => {
    setUniversitiesSelected(
        [...universitiesSelected].filter((u) => u !== tagName)
    );
  };

  const onSubmit = async (data) => {
    const alternativesImage = alternatives.filter(
        (a) => a.alternative.image !== null
    );

    const imagesArrDB = [
      { ...dataQuestion.urlOfImage, typeImage: Object.keys(question)[0] },
      resultOfQuestion && {
        ...resultOfQuestion[0]?.urlOfImage,
        typeImage: Object.keys(question)[1],
      },
      ...(dataQuestion &&
          dataQuestion.keys.map(({ path, urlImage }, index) => ({
            path,
            urlImage,
            typeImage: `alternative-${index}`,
          }))),
    ];

    const imagesArr = Object.values({
      ...(question.question.image
          ? {
            question: {
              image: question?.question.image,
              typeImage: Object.keys(question)[0],
            },
          }
          : {}),
      ...(question.solution?.imageSolution
          ? {
            solution: {
              image: question.solution?.imageSolution,
              typeImage: Object.keys(question)[1],
            },
          }
          : {}),
      ...(alternativesImage.length > 0
          ? alternativesImage.map((a) => ({
            image: a.alternative.image,
            typeImage: `alternative-${a.alternativeId}`,
          }))
          : {}),
    });

    const imagesToDelete = imagesArrDB.reduce((acc, cur) => {
      const obj = imagesArr.filter((item) => item.typeImage === cur.typeImage);
      if (obj.length === 0 && cur.path) {
        acc.push(cur.path);
      }
      return acc;
    }, []);

    if (data.year === optionDefaults.SELECT_YEAR) {
      data.year = null;
    } else {
      data.year = parseInt(data.year);
    }
    data.university = universitiesSelected;

    const result = await onSubmitDataQuestion({
      //mandar el nombre del curso en ingles
      setLoading,
      data,
      db,
      imagesArr,
      alternatives,
      question,
      subTopicSelected,
      uqid: dataQuestion?.uqid,
      dataOfUser,
      courseSelectedName,
      dataQuestion: dataQuestion ?? null,
      imagesToDelete,
    });
    if (result.status === 200) {
      resetValues({ setQuestion, setAlternatives });
      //elimiar dataQuestion de reportedQuestionData del array local
      setReportedQuestionData(
          reportedQuestionData.filter((q) => q.uqid !== idQuestion)
      );
      reset();
      navigate("/edit-question");
    } else {
      alert(result.message);
    }
    setLoading({ status: false, title: null });
  };

  useEffect(() => {
    filterTopics({
      courseSelectedName,
      dataSubTopics,
      setTopicsFilters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSelected, dataSubTopics, setTopicsFilters]);

  useEffect(() => {
    recoveryDataSubTopics({
      db,
      dataSubTopics,
      setDataSubTopics,
      courseSelectedName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSelected]);

  useEffect(() => {
    if (dataSubTopics?.length > 0) {
      const filtered = dataSubTopics?.filter(
          (t) => t.subTopicId === dataQuestion?.subTopicID
      )?.[0]?.topics;
      filtered && setTopicSelected(Object.values(filtered)?.[0]);
      const filteredSubTopic =
          dataSubTopics?.filter(
              (t) => t.subTopicId === dataQuestion?.subTopicID
          )[0]?.subTopicId ?? "";
      setSubTopicSelected(filteredSubTopic);
      setSubTopicSelectedName(
          dataSubTopics?.filter(
              (t) => t.subTopicId === dataQuestion?.subTopicID
          )[0]?.title ?? ""
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSubTopics]);

  useEffect(() => {
    if (resultOfQuestion) {
      setValue(
          "urlVideoYoutube",
          resultOfQuestion ? resultOfQuestion[0]?.urlOfVideo?.youtube : null
      );
      setValue(
          "urlVideoFacebook",
          resultOfQuestion ? resultOfQuestion[0]?.urlOfVideo?.facebook : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultOfQuestion]);

  useEffect(() => {
    if (!resultOfQuestion && dataQuestion) {
      searchSolutionQuestion({
        setLoading,
        db,
        uqid: dataQuestion?.uqid,
        setResultOfQuestion,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultOfQuestion, dataQuestion]);

  useEffect(() => {
    !dataOfUser && navigate("/");
  }, [dataOfUser, navigate]);

  if (loading.status) {
    return (
        <Mainspinner
            title={loading.title ?? "Hey tú, sí tú... ¡ME IMPORTAS MUCHO!"}
        />
    );
  }

  return (
      <main>
        <WrapperAdmin>
          <Title4>Edición de preguntas</Title4>
          <Title5>Observación:</Title5>
          <ReportTextContainer>
            <InputContainer>
            <textarea
                disabled
                value={
                    dataQuestion?.dataReported?.comentOnQuestion ??
                    "No hay observaciones"
                }
            />
            </InputContainer>
          </ReportTextContainer>
          <Title5>Metadatos:</Title5>
          <FormContainer
              onSubmit={(e) => {
                e.preventDefault();
                onChangeStateModal({ modalState, setModalState });
              }}
          >
            <div className="inputContainerChip">
              <InputContainer margin10B>
                <label>Universidad *</label>
                <div className="select">
                  <select
                      id="standard-select"
                      {...register(
                          "university"
                          // requeridValidator
                      )}
                      onChange={(e) =>
                          e.target.selectedIndex !== 0
                              ? setUniversitiesSelected([
                                ...new Set([...universitiesSelected, e.target.value]),
                              ])
                              : undefined
                      }
                  >
                    <option>Seleccione Universidad</option>
                    {universities.map((u, index) => (
                        <option key={index} value={u.acronym}>
                          {u.acronym} - {u.universityName}
                        </option>
                    ))}
                  </select>
                  <span className="focus"></span>
                </div>
              </InputContainer>
              <ul>
                {universitiesSelected.map((chip, index) => (
                    <Tag
                        key={index}
                        name={chip}
                        type="university"
                        onDelete={onTagDeleteU}
                    />
                ))}
              </ul>
            </div>
            {errors.university && (
                <ErrorText>{errors.university.message}</ErrorText>
            )}
            <div className="inputContainerDuplo">
              <InputContainer margin20B labelMax>
                <label>¿Pertenece al centro preuniversitario? (Opcional)</label>
                <div className="inputsRadioContainer">
                  <label className="inputRadioContainer inputType" labelMax>
                    <input
                        // value={true}
                        name="isPreUniversityCheck"
                        type="checkbox"
                        {...register("isPreUniversityCheck")}
                    />
                    Si
                  </label>
                </div>
              </InputContainer>
            </div>
            <div className="inputContainerQuad">
              <InputContainer margin10B>
                <label>Curso *</label>
                <div className="select">
                  <select
                      id="standard-select"
                      defaultValue={courseSelected ?? ""}
                      {...register("course", requeridValidator, {
                        required: true,
                      })}
                      onChange={(e) =>
                          setCourseSelected(e.target?.value ? e.target?.value : [])
                      }
                  >
                    <option>Seleccione curso</option>
                    {listOfCoursesNames?.map((courses, index) => (
                        <option key={index} value={courses}>
                          {courses}
                        </option>
                    ))}
                  </select>
                  <span className="focus"></span>
                </div>
                {errors.course && <ErrorText>{errors.course.message}</ErrorText>}
              </InputContainer>
              <InputContainer noMargin>
                <label>Tema *</label>
                <div className="select">
                  <select
                      id="standard-select"
                      name="topic"
                      value={topicSelected ?? ""}
                      // defaultValue={topicSelected ?? ''}
                      {...register(
                          "topic"
                          // requeridValidator
                      )}
                      onChange={(e) => setTopicSelected(e.target.value)}
                  >
                    <option>Seleccione tema</option>
                    {topicsFilters
                        ?.reduce((acc, topic) => {
                          if (!acc.includes(topic)) {
                            acc.push(topic);
                          }
                          return acc;
                        }, [])
                        .map((topic, index) => (
                            <option key={index} value={topic}>
                              {topic}
                            </option>
                        ))}
                  </select>
                  <span className="focus"></span>
                </div>
                {errors.topic && <ErrorText>{errors.topic.message}</ErrorText>}
              </InputContainer>
              <InputContainer noMargin>
                <label>Subtema * </label>
                <div className="select">
                  <select
                      name="subTopic"
                      id="standard-select"
                      value={subTopicSelectedName ?? ""}
                      {...register(
                          "subTopic"
                          // requeridValidator
                      )}
                      onChange={(e) => {
                        setSubTopicSelected(e.target.selectedOptions[0].id);
                        setSubTopicSelectedName(e.target.value);
                      }}
                  >
                    <option>Seleccione Subtema</option>
                    {dataSubTopics
                        ?.filter(
                            (st) =>
                                st.courses?.includes(courseSelectedName) &&
                                Object.values(st.topics).includes(topicSelected) &&
                                st.title
                        )
                        .map((subtopic, index) => {
                          return (
                              <option
                                  key={index}
                                  value={subtopic.title}
                                  id={subtopic.subTopicId}
                              >
                                {subtopic.title}
                              </option>
                          );
                        })}
                  </select>
                  <span className="focus"></span>
                </div>
                {errors.subTopic && (
                    <ErrorText>{errors.subTopic.message}</ErrorText>
                )}
              </InputContainer>
              <InputContainer noMargin>
                <label>Año (opcional)</label>
                <div className="select">
                  <select
                      id="standard-select"
                      defaultValue={dataQuestion.yearOfQuestion ?? null}
                      {...register("year")}
                  >
                    <option>{optionDefaults.SELECT_YEAR}</option>
                    {generatorYear().map((year, index) => {
                      return (
                          <option key={index} value={year}>
                            {year}
                          </option>
                      );
                    })}
                  </select>
                  <span className="focus"></span>
                </div>
              </InputContainer>
              <InputContainer>
                <label>Semana *</label>
                <div className="select">
                  <select
                      id="standard-select"
                      defaultValue={dataQuestion?.week ?? null}
                      {...register("week", requeridValidator)}
                  >
                    <option>Seleccione la semana</option>
                    {/* Contador de doce semanas no quitar la v*/}
                    {Array.from({ length: 12 }, (v, k) => k + 1).map((val) => (
                        <option key={val} value={val}>
                          Semana {val}
                        </option>
                    ))}
                  </select>
                  <span className="focus"></span>
                </div>
              </InputContainer>
            </div>
            <div className="inputContainerDuplo">
              <InputContainer>
                <label>Pregunta para *</label>
                <div className="inputsRadioContainer">
                  <label className="inputRadioContainer inputType">
                    <input
                        value="simulacro"
                        name="typeOfQuestion"
                        type="checkbox"
                        {...register("typeQuestion", typeQuestionValidator)}
                    />
                    Simulacros
                  </label>
                  <label className="inputRadioContainer inputType">
                    <input
                        value="cuestionario"
                        name="typeOfQuestion"
                        type="checkbox"
                        {...register("typeQuestion", typeQuestionValidator)}
                    />
                    Questionario
                  </label>
                  <label className="inputRadioContainer inputType">
                    <input
                        value={"deco"}
                        name="typeOfQuestion"
                        type="checkbox"
                        {...register("typeQuestion", typeQuestionValidator)}
                    />
                    DECO
                  </label>
                </div>
                {errors.typeQuestion && (
                    <ErrorText>{errors.typeQuestion.message}</ErrorText>
                )}
              </InputContainer>
            </div>
            <WrapperDuplex>
              <div>
                <Title5>Contenido</Title5>
                <div>
                  <Title6>Pregunta *</Title6>
                  <div>
                    <InputSvg
                        defaultValueQuestion={dataQuestion?.latexQuestion}
                        heightTextArea="300px"
                        type="textArea"
                        question={question}
                        setQuestion={setQuestion}
                        isQuestion={true}
                        setSuperiorSelections={setSuperiorSelections}
                        urlOfImage={dataQuestion?.urlOfImage?.urlImage}
                    />
                  </div>
                  <Title6>Insertar funciones LATEX (Opcional)</Title6>
                  <InputContainer noMargin>
                    <div className="select">
                      <select
                          id="standard-select-category"
                          onChange={(e) => setSelectionCategory(e.target.value)}
                      >
                        <option>Seleccione función a insertar </option>
                        {functionLatex.map((lat, index) => {
                          return (
                              <option
                                  key={index}
                                  value={`${lat.category}${
                                      lat.subCategory ? "-" + lat.subCategory : ""
                                  }`}
                              >{`${lat.category}${
                                  lat.subCategory ? "-" + lat.subCategory : ""
                              }`}</option>
                          );
                        })}
                      </select>
                    </div>
                  </InputContainer>
                </div>
                <ButtonsLatexUnlist>
                  {functionLatex
                      .filter((lat) =>
                          selectionCategory.split("-")?.length > 1
                              ? lat.category === selectionCategory.split("-")[0] &&
                              lat.subCategory === selectionCategory.split("-")[1]
                              : lat.category === selectionCategory
                      )
                      .map((lat, index) =>
                          lat.functions.map((func, index) => (
                              <li>
                                <ButtonLatex
                                    type="button"
                                    primary
                                    secundary
                                    inForm
                                    className={func.styleButton}
                                    key={index}
                                    value={func.expressionLatex}
                                    id={index}
                                    onClick={() => {
                                      handleClickFunction({
                                        funcExpression: func.expressionLatex,
                                        superiorSelections,
                                      });
                                    }}
                                >
                                  <Latex key={index}>{func.expressionLatex}</Latex>
                                </ButtonLatex>
                              </li>
                          ))
                      )}
                </ButtonsLatexUnlist>
                <div>
                  <Title6>Alternativas *</Title6>
                  <div>
                    {alternatives.map((key, index) => (
                        <InputSvg
                            key={index}
                            type="textArea"
                            isQuestion={false}
                            valueRecovery={dataQuestion?.keys[index]?.key ?? null}
                            number={`${index + 1}.`}
                            setAlternatives={setAlternatives}
                            alternatives={alternatives}
                            setSuperiorSelections={setSuperiorSelections}
                            alternativeId={key.alternativeId}
                            urlOfImage={dataQuestion?.keys[index]?.urlImage ?? null}
                        />
                    ))}
                  </div>
                </div>
              </div>
              {/* previsualizacion de la pregunta */}
              <QuestionPreview
                  question={question}
                  alternatives={alternatives}
                  isEdit={true}
              />
            </WrapperDuplex>
            <div>
              <Title5>Resolución</Title5>
              <WrapperDuplex>
                <InputContainer margin="0 0 10px 0">
                  <label>URL del video de Youtube (Opcional)</label>
                  <input
                      type="url"
                      {...register("urlVideoYoutube", urlVideoYoutubeValidatorNR)}
                      // defaultValue={resultOfQuestion ? resultOfQuestion[0]?.urlOfVideo?.youtube : ''}
                      onChange={(e) => {
                        setQuestion({
                          ...question,
                          solution: {
                            ...question.solution,
                            urlVideoYoutube: e.target.value,
                          },
                        });
                      }}
                  />
                  {errors.urlVideoYoutube && (
                      <ErrorText>{errors.urlVideoYoutube.message}</ErrorText>
                  )}
                </InputContainer>
                <InputContainer margin="0 0 10px 0">
                  <label>URL del video de Facebook (Opcional)</label>
                  <input
                      type="url"
                      {...register("urlVideoFacebook", urlVideoFacebookValidatorNR)}
                      // defaultValue={resultOfQuestion ? resultOfQuestion[0]?.urlOfVideo?.facebook : ''}
                      onChange={(e) => {
                        setQuestion({
                          ...question,
                          solution: {
                            ...question.solution,
                            urlVideoFacebook: e.target.value,
                          },
                        });
                      }}
                  />
                  {errors.urlVideoFacebook && (
                      <ErrorText>{errors.urlVideoFacebook.message}</ErrorText>
                  )}
                </InputContainer>
              </WrapperDuplex>
              <div>
                <InputSvg
                    heightTextArea="250px"
                    type="textArea"
                    label="Texto e imagen (Opcional)"
                    isQuestion={false}
                    question={question}
                    valueRecovery={
                      resultOfQuestion ? resultOfQuestion[0]?.justification : null
                    }
                    setQuestion={setQuestion}
                    setSuperiorSelections={setSuperiorSelections}
                    urlOfImage={resultOfQuestion?.[0]?.urlOfImage?.urlImage ?? null}
                />
              </div>
            </div>
            <WrapperDuplex>
              <Button primary formEnd type="submit">
                Enviar a revisión
              </Button>
            </WrapperDuplex>
          </FormContainer>
          <MainModalUpload
              modalState={modalState}
              setModalState={setModalState}
              title="¿Estas segur@ de enviar la pregunta?"
              functionUpload={handleSubmit(onSubmit)}
          />
        </WrapperAdmin>
      </main>
  );
};