import { collection, getDocs, query, Timestamp, where } from 'firebase/firestore';

export const recoverData = async ({ dataRequiredSelected, db, setLocalLoading }) => {
  let data = [];
  setLocalLoading(true);
  if (dataRequiredSelected === 'users') {
    try {
      const refUsers = collection(db, 'users');
      const snapshot = await getDocs(refUsers);
      snapshot.forEach((doc) => {
        data.push(doc.data());
      });
      return data;
    } catch (error) {
      throw new Error(error);
    }
  } else if (dataRequiredSelected === 'contact') {
    try {
      const refContact = collection(db, 'contact');
      const snapshot = await getDocs(refContact);
      snapshot.forEach((doc) => {
        data.push(doc.data());
      });
      return data;
    } catch (error) {
      throw new Error(error);
    }
  } else if (dataRequiredSelected === 'usersActive') {
    try {
      const refUsers = collection(db, 'users');
      const q = query(refUsers, where('subscriptionData.endTimestamp', '>', Timestamp.now()));
      const snapshot = await getDocs(q);
      snapshot.forEach((doc) => {
        data.push(doc.data());
      });
      const today = new Date();
      const todayTimestamp = Timestamp.fromDate(today);
      data.forEach((user) => {
        //calcula los dias restantes
        const daysRemaining =
          user.subscriptionData.endTimestamp.toDate().getTime() - todayTimestamp.toDate().getTime();
        const daysRemainingInDays = daysRemaining / (1000 * 60 * 60 * 24);
        user.daysRemaining = daysRemainingInDays;

        //concatear el nombre y el apellido
        user.FullName = `${user?.name?.firstName ?? ''} ${user?.name?.lastName ?? ''}`;

        //concatenando nombre del apoderado
        user.fullNameAttorney = `${user?.nameAttorney?.name ?? ''} ${
          user?.nameAttorney?.lastName ?? ''
        }`;

        //eliminando las propiedades innecesarias
        delete user?.nameAttorney;
        delete user?.name;
        delete user?.subscriptionData;
        delete user?.sended;
        delete user?.metadata;
        delete user?.profileEdited;
      });
      return data;
    } catch (error) {
      throw new Error(error);
    }
  } else {
    return data;
  }
};
