import styled, { css } from "styled-components";

export const LatexTextContainer = styled.span`
  ${(props) =>
    props.inPreview === true &&
    css`
      font-size: 0.85em !important;
      line-height: 1.5em !important;
      color: ${(props) => props.theme.gray1900};
    `}
`;

export const LatexContainer = styled.div`
  ${(props) =>
    props.withBorder &&
    css`
      overflow: hidden;
      border: 1px solid ${(props) => props.theme.gray1100};
      padding: 5px;
    `}
`;
