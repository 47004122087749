import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { AppContext } from "../../../App";
import { FirestoreSdkContext } from "reactfire";
import "katex/dist/katex.min.css";
import { Latex } from "../../latex/Latex";

// IMPORT COMPONENTS AND STYLES
import { Title4, Title5, Title6 } from "./../../../styles/textGeneral";
import { Button } from "./../../../styles/buttonGeneral";
import { WrapperAdmin } from "./../../../styles/generalStyles";
import { MainModalUpload } from "../.././modal/MainModalUpload";
import { ErrorText } from "./styles/sErrorText";
import { InputContainer, FormContainer } from "./../../../styles/inputGeneral";
import { WrapperDuplex } from "./../../../styles/boxesGeneral";
import { ButtonLatex } from "./styles/sButtonLatex";
import Tag from "../../general/cOthers/Tag";
import InputSvg from "./../../general/cOthers/InputSvg";
import Mainspinner from "../../spinner/MainSpinner";

//IMPORTS FUNCTIONS
import { recoveryDataSubTopics } from "./algorithms/recoveryDataSubtopics";
import { functionLatex } from "../functionsLatex/functionsLatex";
import { onSubmitDataQuestion } from "./algorithms/onSubmitDataQuestion";
import {
  handleClickFunction,
  filterTopics,
  resetValues,
  onChangeStateModal,
} from "./functions";
import {
  urlVideoFacebookValidatorNR,
  urlVideoYoutubeValidatorNR,
  typeQuestionValidator,
  requeridValidator,
} from "./validators/formValidators";
import { generatorYear } from "./algorithms/generatorYear";
import { optionDefaults } from "../../../constants/generalConstants";
import { QuestionPreview } from "./QuestionPreview";
import {ButtonsLatexUnlist} from "../editQuestion/styles/sEditQuestion";

export default function MainWriteQuestion() {
  const navigate = useNavigate();
  const {
    universities,
    dataSubTopics,
    setDataSubTopics,
    listOfCourses,
    dataOfUser,
  } = useContext(AppContext);
  const db = useContext(FirestoreSdkContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const [loading, setLoading] = useState({ status: false, title: null });
  const [modalState, setModalState] = useState(false);
  const [universitiesSelected, setUniversitiesSelected] = useState([]);
  const [courseSelected, setCourseSelected] = useState(null);
  const [topicsFilters, setTopicsFilters] = useState([]);
  const [topicSelected, setTopicSelected] = useState(null);
  const [subTopicSelected, setSubTopicSelected] = useState(null);
  const [selectionCategory, setSelectionCategory] = useState("");
  const [superiorSelections, setSuperiorSelections] = useState({
    selections: { start: 0, end: 0 },
    setSelections: null,
    setInferiorText: null,
  });

  const [question, setQuestion] = useState({
    question: {
      image: null,
      text: null,
      plainText: null,
      urlImage: null,
    },
    solution: {
      imageSolution: null,
      urlImageSolution: null,
      textSolution: null,
      plainTextSolution: null,
      urlVideoYoutube: null,
      urlVideoFacebook: null,
    },
  });

  const [alternatives, setAlternatives] = useState([
    {
      alternativeId: 1,
      alternative: { image: null, text: null, plainText: null },
    },
    {
      alternativeId: 2,
      alternative: { image: null, text: null, plainText: null },
    },
    {
      alternativeId: 3,
      alternative: { image: null, text: null, plainText: null },
    },
    {
      alternativeId: 4,
      alternative: { image: null, text: null, plainText: null },
    },
    {
      alternativeId: 5,
      alternative: { image: null, text: null, plainText: null },
    },
  ]);

  const listOfCoursesNames = listOfCourses
    ?.map((course) => course.value)
    .sort((a, b) => a.localeCompare(b));

  const courseSelectedName = listOfCourses?.find(
    (c) => c.value === courseSelected
  )?.name;

  const onTagDeleteU = (tagName) => {
    setUniversitiesSelected(
      [...universitiesSelected].filter((u) => u !== tagName)
    );
  };

  const onSubmit = async (data) => {
    const alternativesImage = alternatives.filter(
      (a) => a.alternative.image !== null
    );

    const imagesArr = Object.values({
      ...(question.question.image
        ? {
            question: {
              image: question?.question.image,
              typeImage: Object.keys(question)[0],
            },
          }
        : {}),
      ...(question.solution?.imageSolution
        ? {
            solution: {
              image: question.solution?.imageSolution,
              typeImage: Object.keys(question)[1],
            },
          }
        : {}),
      ...(alternativesImage.length > 0
        ? alternativesImage.map((a) => ({
            image: a.alternative.image,
            typeImage: `alternative-${a.alternativeId}`,
          }))
        : {}),
    });
    if (data.year === optionDefaults.SELECT_YEAR) {
      data.year = null;
    } else {
      data.year = parseInt(data.year);
    }
    data.university = universitiesSelected;

    const result = await onSubmitDataQuestion({
      //mandar el nombre del curso en ingles
      setLoading,
      data,
      db,
      imagesArr,
      alternatives,
      question,
      subTopicSelected,
      dataOfUser,
      courseSelectedName,
    });
    if (result.status === 200) {
      resetValues({ setQuestion, setAlternatives });
    } else {
      alert(result.message);
    }
    setLoading({ status: false, title: null });
  };

  useEffect(() => {
    filterTopics({
      courseSelectedName,
      dataSubTopics,
      setTopicsFilters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSelected, dataSubTopics, setTopicsFilters]);

  useEffect(() => {
    recoveryDataSubTopics({
      db,
      dataSubTopics,
      setDataSubTopics,
      courseSelectedName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSelected]);

  useEffect(() => {
    !dataOfUser && navigate("/");
  }, [dataOfUser, navigate]);

  if (loading.status) {
    return (
      <Mainspinner
        title={loading.title ?? "Hey tú, sí tú... ¡ME IMPORTAS MUCHO!"}
      />
    );
  }
  return (
    <main>
      <WrapperAdmin>
        <div>
          <Title4>Redacción de preguntas</Title4>
        </div>
        <div>
          <Title5>Metadatos</Title5>
          <FormContainer
            onSubmit={(e) => {
              e.preventDefault();
              onChangeStateModal({ modalState, setModalState });
            }}
          >
            <div className="inputContainerChip">
              <InputContainer margin10B>
                <label>Universidad *</label>
                <div className="select">
                  <select
                    id="standard-select"
                    {...register("university", requeridValidator)}
                    onChange={(e) =>
                      e.target.selectedIndex !== 0
                        ? setUniversitiesSelected([
                            ...new Set([
                              ...universitiesSelected,
                              e.target.value,
                            ]),
                          ])
                        : undefined
                    }
                  >
                    <option>Seleccione Universidad</option>
                    {universities.map((u, index) => (
                      <option key={index} value={u.acronym}>
                        {u.acronym} - {u.universityName}
                      </option>
                    ))}
                  </select>
                  <span className="focus"></span>
                </div>
              </InputContainer>
              {universitiesSelected.map((chip, index) => (
                <Tag
                  key={index}
                  name={chip}
                  type="university"
                  onDelete={onTagDeleteU}
                />
              ))}
            </div>
            {errors.university && (
              <ErrorText>{errors.university.message}</ErrorText>
            )}
            <div className="inputContainerDuplo">
              <InputContainer margin20B labelMax>
                <label>¿Pertenece al centro preuniversitario? (Opcional)</label>
                <div className="inputsRadioContainer">
                  <label className="inputRadioContainer inputType">
                    <input
                      value={true}
                      name="isPreUniversityCheck"
                      type="checkbox"
                      {...register("isPreUniversityCheck")}
                    />
                    Si
                  </label>
                </div>
              </InputContainer>
            </div>
            <div className="inputContainerQuad">
              <InputContainer margin10B>
                <label>Curso *</label>
                <div className="select">
                  <select
                    id="standard-select"
                    {...register("course", requeridValidator, {
                      required: true,
                    })}
                    defaultValue={courseSelected ?? ""}
                    onChange={(e) =>
                      setCourseSelected(e.target?.value ? e.target?.value : [])
                    }
                  >
                    <option>Seleccione curso</option>
                    {listOfCoursesNames?.map((courses, index) => (
                      <option key={index} value={courses}>
                        {courses}
                      </option>
                    ))}
                  </select>
                  <span className="focus"></span>
                </div>
                {errors.course && (
                  <ErrorText>{errors.course.message}</ErrorText>
                )}
              </InputContainer>
              <InputContainer noMargin>
                <label>Tema *</label>
                <div className="select">
                  <select
                    id="standard-select"
                    defaultValue={topicSelected ?? ""}
                    {...register("topic", requeridValidator)}
                    onChange={(e) => setTopicSelected(e.target.value)}
                  >
                    <option>Seleccione tema</option>
                    {topicsFilters
                      ?.reduce((acc, topic) => {
                        if (!acc.includes(topic)) {
                          acc.push(topic);
                        }
                        return acc;
                      }, [])
                      .map((topic, index) => (
                        <option key={index} value={topic}>
                          {topic}
                        </option>
                      ))}
                  </select>
                  <span className="focus"></span>
                </div>
                {errors.topic && <ErrorText>{errors.topic.message}</ErrorText>}
              </InputContainer>
              <InputContainer noMargin>
                <label>Subtema * </label>
                <div className="select">
                  <select
                    id="standard-select"
                    {...register("subTopic", requeridValidator)}
                    defaultValue={
                      dataSubTopics.length > 0 ? dataSubTopics : undefined
                    }
                    onChange={(e) =>
                      setSubTopicSelected(e.target.selectedOptions[0].id)
                    }
                  >
                    <option>Seleccione Subtema</option>
                    {dataSubTopics
                      ?.filter(
                        (st) =>
                          st.courses?.includes(courseSelectedName) &&
                          Object.values(st.topics).includes(topicSelected) &&
                          st.title
                      )
                      .map((subtopic, index) => {
                        return (
                          <option
                            key={index}
                            value={subtopic.title}
                            id={subtopic.subTopicId}
                          >
                            {subtopic.title}
                          </option>
                        );
                      })}
                  </select>
                  <span className="focus"></span>
                </div>
                {errors.subTopic && (
                  <ErrorText>{errors.subTopic.message}</ErrorText>
                )}
              </InputContainer>
              <InputContainer noMargin>
                <label>Año (opcional)</label>
                <div className="select">
                  <select
                    id="standard-select"
                    defaultValue={null}
                    {...register("year")}
                  >
                    <option>{optionDefaults.SELECT_YEAR}</option>
                    {generatorYear().map((year, index) => {
                      return (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      );
                    })}
                  </select>
                  <span className="focus"></span>
                </div>
              </InputContainer>
              <InputContainer noMargin>
                <label>Semana *</label>
                <div className="select">
                  <select
                    id="standard-select"
                    defaultValue={null}
                    {...register("week", requeridValidator)}
                  >
                    <option>Seleccione la semana</option>
                    {/* Contador de doce semanas no quitar la v*/}
                    {Array.from({ length: 12 }, (v, k) => k + 1).map((val) => (
                      <option key={val} value={val}>
                        Semana {val}
                      </option>
                    ))}
                  </select>
                  <span className="focus"></span>
                </div>
              </InputContainer>
            </div>
            <div className="inputContainerDuplo">
              <InputContainer noMargin>
                <label>Pregunta para *</label>
                <div className="inputsRadioContainer">
                  <label className="inputRadioContainer inputType">
                    <input
                      value="simulacro"
                      name="typeOfQuestion"
                      type="checkbox"
                      {...register("typeQuestion", typeQuestionValidator)}
                    />
                    Simulacros
                  </label>
                  <label className="inputRadioContainer inputType">
                    <input
                      value="cuestionario"
                      name="typeOfQuestion"
                      type="checkbox"
                      {...register("typeQuestion", typeQuestionValidator)}
                    />
                    Questionario
                  </label>
                  <label className="inputRadioContainer inputType">
                    <input
                      value={"deco"}
                      name="typeOfQuestion"
                      type="checkbox"
                      {...register("typeQuestion", typeQuestionValidator)}
                    />
                    DECO
                  </label>
                </div>
                {errors.typeQuestion && (
                  <ErrorText>{errors.typeQuestion.message}</ErrorText>
                )}
              </InputContainer>
            </div>
            <WrapperDuplex>
              <div>
                <Title5>Contenido</Title5>
                <div>
                  <Title6>Pregunta *</Title6>
                  <div>
                    <InputSvg
                      heightTextArea="300px"
                      type="textArea"
                      question={question}
                      setQuestion={setQuestion}
                      isQuestion={true}
                      setSuperiorSelections={setSuperiorSelections}
                    />
                  </div>
                  <Title6>Insertar funciones LATEX (Opcional)</Title6>
                  <InputContainer noMargin>
                    <div className="select">
                      <select
                        id="standard-select-category"
                        onChange={(e) => setSelectionCategory(e.target.value)}
                      >
                        <option>Seleccione función a insertar </option>
                        {functionLatex.map((lat, index) => {
                          return (
                            <option
                              key={index}
                              value={`${lat.category}${
                                lat.subCategory ? "-" + lat.subCategory : ""
                              }`}
                            >{`${lat.category}${
                              lat.subCategory ? "-" + lat.subCategory : ""
                            }`}</option>
                          );
                        })}
                      </select>
                    </div>
                  </InputContainer>
                </div>
                <ButtonsLatexUnlist>
                  {functionLatex
                    .filter((lat) =>
                      selectionCategory.split("-")?.length > 1
                        ? lat.category === selectionCategory.split("-")[0] &&
                          lat.subCategory === selectionCategory.split("-")[1]
                        : lat.category === selectionCategory
                    )
                    .map((lat, index) =>
                      lat.functions.map((func, index) => (
                        <ButtonLatex
                          type="button"
                          primary
                          secundary
                          inForm
                          className={func.styleButton}
                          key={index}
                          value={func.expressionLatex}
                          id={index}
                          onClick={() => {
                            handleClickFunction({
                              funcExpression: func.expressionLatex,
                              superiorSelections,
                            });
                          }}
                        >
                          <Latex key={index}>{func.expressionLatex}</Latex>
                        </ButtonLatex>
                      ))
                    )}
                </ButtonsLatexUnlist>
                <div>
                  <Title6>Alternativas *</Title6>
                  <div>
                    {alternatives.map((key, index) => (
                      <InputSvg
                        key={index}
                        type="textArea"
                        isQuestion={false}
                        number={`${index + 1}.`}
                        setAlternatives={setAlternatives}
                        alternatives={alternatives}
                        setSuperiorSelections={setSuperiorSelections}
                        alternativeId={key.alternativeId}
                      />
                    ))}
                  </div>
                </div>
              </div>
              {/* previsualizacion de la pregunta */}
              <QuestionPreview
                question={question}
                alternatives={alternatives}
              />
            </WrapperDuplex>
            <div>
              <Title5>Resolución</Title5>
              <WrapperDuplex>
                <InputContainer margin="0 0 10px 0">
                  <label>URL del video de Youtube (Opcional)</label>
                  <input
                    type="url"
                    defaultValue={""}
                    {...register("urlVideoYoutube", urlVideoYoutubeValidatorNR)}
                    onChange={(e) => {
                      setQuestion({
                        ...question,
                        solution: {
                          ...question.solution,
                          urlVideoYoutube: e.target.value,
                        },
                      });
                    }}
                  />
                  {errors.urlVideoYoutube && (
                    <ErrorText>{errors.urlVideoYoutube.message}</ErrorText>
                  )}
                </InputContainer>
                <InputContainer margin="0 0 10px 0">
                  <label>URL del video de Facebook (Opcional)</label>
                  <input
                    type="url"
                    defaultValue={""}
                    {...register(
                      "urlVideoFacebook",
                      urlVideoFacebookValidatorNR
                    )}
                    onChange={(e) => {
                      setQuestion({
                        ...question,
                        solution: {
                          ...question.solution,
                          urlVideoFacebook: e.target.value,
                        },
                      });
                    }}
                  />
                  {errors.urlVideoFacebook && (
                    <ErrorText>{errors.urlVideoFacebook.message}</ErrorText>
                  )}
                </InputContainer>
              </WrapperDuplex>
              <div>
                <InputSvg
                  heightTextArea="300px"
                  type="textArea"
                  label="Texto e imagen (Opcional)"
                  isQuestion={false}
                  question={question}
                  setQuestion={setQuestion}
                  setSuperiorSelections={setSuperiorSelections}
                />
              </div>
            </div>
            <WrapperDuplex>
              <Button primary formEnd type="submit">
                Enviar a revisión
              </Button>
            </WrapperDuplex>
          </FormContainer>
          <MainModalUpload
            modalState={modalState}
            setModalState={setModalState}
            title="¿Estas seguro de enviar la pregunta?"
            functionUpload={handleSubmit(onSubmit)}
          />
        </div>
      </WrapperAdmin>
    </main>
  );
}
