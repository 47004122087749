import {
  collection,
  doc,
  setDoc,
  serverTimestamp,
  runTransaction,
} from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { onSubmitImage } from "./onSubmitImage";
import { v4 as uuidv4 } from "uuid";

export const onSubmitDataQuestion = async ({
  setLoading,
  data,
  db,
  imagesArr,
  question,
  alternatives,
  subTopicSelected,
  uqid = null,
  dataOfUser,
  courseSelectedName,
  dataQuestion = null,
  imagesToDelete = [],
}) => {
  setLoading({ status: true, title: "Enviando pregunta ..." });

  const generateId = async () => {
    const id = uuidv4();
    const questionsRef = doc(db, "questions", id);
    const questionsBankRef = doc(db, "questionsBank", id);
    const docSnapResult = await runTransaction(db, async (transaction) => {
      const docSnapQuestions = await transaction.get(questionsRef);
      const docSnapQuestionsBank = await transaction.get(questionsBankRef);
      return docSnapQuestions.exists() || docSnapQuestionsBank.exists();
    });

    if (docSnapResult) {
      return await generateId();
    }
    return id;
  };

  let uuid = uqid ?? (await generateId());

  if (!subTopicSelected)
    return {
      status: 400,
      message: "Falta definir el subtema de la pregunta",
      errorCode: "SUBTOPIC_NOT_DEFINED",
    };
  try {
    const {
      university,
      year,
      typeQuestion,
      urlVideoFacebook,
      urlVideoYoutube,
      isPreUniversityCheck,
    } = data;

    const refQuestionsDb = doc(collection(db, "questions"), uuid);
    const refSolutionsDb = doc(collection(db, "solutions"), uuid);

    const dataUrls =
      imagesArr.length > 0
        ? await onSubmitImage({
            imagesArr,
            setLoading,
            uuid,
            course: courseSelectedName,
            dataQuestion: dataQuestion ?? null,
          })
        : [];
    if (!Array.isArray(dataUrls))
      return {
        status: 400,
        message: "Error al subir las imagenes",
        errorCode: "ERROR_UPLOAD_IMAGES",
      };

    const questionData = alternatives.reduce(
      (acc, curr) => {
        acc.urlOfImage.typeImage && delete acc.urlOfImage.typeImage;
        const urlsObject = dataUrls.filter(
          (url) => parseInt(url.alternativeId) === curr.alternativeId
        );
        acc.SEOAlternatives.push({ key: curr.alternative.plainText });
        const urlKey = {
          ...(urlsObject.length === 1
            ? urlsObject[0]
            : { path: null, urlImage: null }),
        };
        delete urlKey.alternativeId;
        delete urlKey.typeImage;
        acc.keys.push({
          key: curr.alternative?.text.replaceAll(" ", "\\space "),
          ...urlKey,
        });
        return acc;
      },
      {
        uqid: uuid,
        revisedQuestion: false,
        isReported: false,
        authorId: dataOfUser?.uid,
        dateUpload: serverTimestamp(),
        university: university,
        yearOfQuestion: year ?? null,
        course: courseSelectedName ?? null,
        isQuestionOfPreuniversity:
          isPreUniversityCheck === "true" ? true : false,
        subTopicID: subTopicSelected,
        latexQuestion: question.question?.text.replaceAll(" ", "\\space "),
        SEOQuestion: question.question.plainText,
        typeQuestion: typeQuestion,
        week: parseInt(data.week),
        urlOfImage: {
          ...(dataUrls.length > 0
            ? dataUrls.filter((obj) => obj.typeImage === "question")[0]
            : { path: null, urlImage: null }),
        },
        keys: [],
        SEOAlternatives: [],
      }
    );

    const solutionData = {
      uqid: uuid,
      SEOjustification: question.solution.plainTextSolution,
      justification: question.solution.textSolution.replaceAll(" ", "\\space "),
      urlOfImage: {
        ...(dataUrls.length > 0
          ? dataUrls.filter((obj) => obj.typeImage === "solution")[0]
          : { path: null, urlImage: null }),
      },
      urlOfVideo: {
        facebook: urlVideoFacebook,
        youtube: urlVideoYoutube,
      },
    };

    if (imagesToDelete.length > 0) {
      const storage = getStorage();
      // console.log(imagesToDelete);
      imagesToDelete.forEach((path) => {
        const imageRef = ref(storage, path);
        deleteObject(imageRef);
      });
    }

    setDoc(refQuestionsDb, questionData, { merge: true });
    setDoc(refSolutionsDb, solutionData, { merge: true });

    return {
      status: 200,
      message: "Pregunta enviada correctamente",
    };
  } catch (error) {
    console.error(error);
    return {
      status: 500,
      message: "Error al enviar la pregunta",
      errorCode: "ERROR_SENDING_QUESTION",
    };
  }
};
