import React, { useContext, useState, useEffect } from 'react';
import { FirestoreSdkContext } from 'reactfire';
import { AppContext } from '../../App';
import { Title4 } from '../../styles/textGeneral';
import { Button } from '../../styles/buttonGeneral';
import { WrapperMarketing } from '../../styles/generalStyles';
import { InputContainer } from '../../styles/inputGeneral';
import { optionDefaults } from '../../constants/generalConstants';
import { OptionsContainer } from './styles/sMainStudentsData';
import Spinner from '../spinner/Spinner';

import { recoverData } from './algorithms/recoverData';
import { handleDownloadXLSX } from './algorithms/handleDownloadXLSX';
import { sendDownloadLogs } from './algorithms/sendDownloadLogs';

const dataRequiredOptions = [
  { name: 'Usuarios registrados', value: 'users' },
  { name: 'Contactos nuevos', value: 'contact' },
  { name: 'Clientes activos', value: 'usersActive' },
];

const initialstate = [{ data: null, loading: true, error: null }];

export default function MainStudentsData() {
  const { dataOfUser } = useContext(AppContext);
  const db = useContext(FirestoreSdkContext);
  const [data, setData] = useState(initialstate);
  const [dataRequiredSelected, setDataRequiredSelected] = useState(optionDefaults.SELECTED_ANY_OPTION);
  const [localLoading, setLocalLoading] = useState(true);

  const handleReset = () => {
    setDataRequiredSelected(optionDefaults.SELECTED_ANY_OPTION);
    setData(initialstate);
  };

  useEffect(() => {
    recoverData({ dataRequiredSelected, db, setLocalLoading })
      .then((data) => {
        setData(data);
        setLocalLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dataRequiredSelected]);

  const dataRequiredSelectedName = dataRequiredOptions.find(
    (option) => option.value === dataRequiredSelected,
  )?.name;
  return (
    <main>
      <WrapperMarketing>
        <div>
          <Title4>Datos de estudiantes</Title4>
        </div>
        <OptionsContainer>
          <div className="container-input-reset">
            <InputContainer margin10B>
              <label>Seleccionar data requerida:</label>
              <div className="select">
                <select
                  id="standard-select"
                  value={dataRequiredSelected}
                  onChange={(e) => setDataRequiredSelected(e.target.value)}
                >
                  <option value={optionDefaults.SELECTED_ANY_OPTION}>Seleccione una opción</option>
                  {dataRequiredOptions && dataRequiredOptions?.map((options, index) => (
                    <option key={index} value={options.value}>
                      {options.name}
                    </option>
                  ))}
                </select>
                <span className="focus"></span>
              </div>
            </InputContainer>
            {dataRequiredSelected &&
              dataRequiredSelected !== optionDefaults.SELECTED_ANY_OPTION && (
                <Button secundary iris onClick={() => handleReset()}>
                  Reset
                </Button>
              )}
          </div>
          {
            localLoading ? <Spinner title="Cargando datos ..." height={'100%'}/> : <>
              {data.length !== 0 && (
                <p>
                  Data listo para descargar:{' '}
                  <span>
                {dataRequiredOptions &&
                  dataRequiredOptions.find((option) => option.value === dataRequiredSelected)?.name}
              </span>
                </p>
              )}
              <Button
                disabled={dataRequiredSelected === optionDefaults.SELECTED_ANY_OPTION}
                primary
                onClick={
                  dataRequiredSelected && dataRequiredSelected !== optionDefaults.SELECTED_ANY_OPTION
                    ? () => {
                      sendDownloadLogs({ db, dataOfUser, dataRequiredSelectedName });
                      handleDownloadXLSX({ data, dataRequiredSelected });
                    }
                    : null
                }
              >
                Descargar
              </Button>
            </>
          }
        </OptionsContainer>
      </WrapperMarketing>
    </main>
  );
}
