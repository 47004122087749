// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import { WrapperAdmin } from "../../../styles/generalStyles";
import {
  ErrorText,
  SuccessText,
  Title2,
  Title4,
  Title5,
} from "../../../styles/textGeneral";
import { InputContainer } from "../../../styles/inputGeneral";
import { Button } from "../../../styles/buttonGeneral";
import { SearchContainerSubscriptions } from "./styles/sMainSubscription";
import { recoverUserForEmail } from "../algorithm/recoverUserForEmail";
import { FirestoreSdkContext } from "reactfire";
import { updateUserEndTimestamp } from "../algorithm/updateUserEndTimestamp";
import { convertTimestampToDate } from "../algorithm/convertTimestampToDate";
import { MainSearchUser } from "./styles/MainSearchUser";
import { Link } from "react-router-dom";
import { MainAddSubscriber } from "../addSucriptor/MainAddSubscriber";

export const MainSubscription = () => {
  const db = useContext(FirestoreSdkContext);

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [dateMax, setDateMax] = useState(null);
  const [emailForSearch, setEmailForSearch] = useState("");
  const onSearchUser = async ({ emailForSearch }) => {
    setUser(null);
    setError(null);
    setLoading(true);
    const usersData = await recoverUserForEmail({
      db,
      emailForSearch,
    });
    const usersRegisteredData = await recoverUserForEmail({
      db,
      emailForSearch,
      nameDb: "usersRegistered",
    });

    if (usersData) {
      setUser(usersData);
    } else if (usersRegisteredData) {
      setUser(usersRegisteredData);
    } else {
      setError("No se encontró el usuario");
    }

    setLoading(false);
  };

  const onUpdateSubscriptionUser = async ({ uid, date }) => {
    const endTimestamp = new Date(date);
    setLoadingSubmit(true);
    const updateSubscription = await updateUserEndTimestamp({
      db,
      uid,
      endTimestamp,
      nameDb: user.isTemporal ? "usersRegistered" : "users",
    });

    updateSubscription && setSuccess(true);
    setLoadingSubmit(false);
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    onUpdateSubscriptionUser({ uid: user.uid, date: dateMax });
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
        setUser(null);
      }, 3000);
    }
  }, [success]);
  return (
    <main>
      <WrapperAdmin>
        <Title2>👤 Actualicemos las suscripciones 🆕📅</Title2>
        <MainSearchUser
          onSearchUser={onSearchUser}
          emailForSearch={emailForSearch}
          setEmailForSearch={setEmailForSearch}
        />
        {loading && <Title5>Buscando usuario...</Title5>}

        {!!user && (
          <>
            <Title4>Usuario: {user.username}</Title4>
            <SearchContainerSubscriptions>
              <form onSubmit={onHandleSubmit}>
                <InputContainer>
                  <label>Fecha de expiración:</label>
                  <input
                    type="date"
                    defaultValue={
                      user?.subscriptionData?.endTimestamp
                        ? convertTimestampToDate({
                            timestampSeconds:
                              user.subscriptionData.endTimestamp.seconds,
                            timestampNanoseconds:
                              user.subscriptionData.endTimestamp.nanoseconds,
                          })
                        : undefined
                    }
                    onChange={(e) => setDateMax(e.target.value)}
                    required
                  />
                </InputContainer>
                <Button primary disabled={loadingSubmit}>
                  {loadingSubmit ? "Actualizando..." : "Actualizar"}
                </Button>
              </form>
            </SearchContainerSubscriptions>
          </>
        )}
        {!!!user && error && (
          <MainAddSubscriber
            emailForSearch={emailForSearch}
            setErrorState={setError}
            setSuccessState={setSuccess}
          />
        )}
        {success && <SuccessText>Operacion exitosa!</SuccessText>}
      </WrapperAdmin>
    </main>
  );
};
