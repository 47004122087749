let XLSX = require('xlsx');

const formatTimestamp = (timestamp) => new Date(
  timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000,
);

export const handleDownloadXLSX = ({ data, dataRequiredSelected }) => {
  try {
    if(data) {
      let isContact = dataRequiredSelected === 'contact';
      let dataContact = isContact && data?.map(item => {
        let formatLocaleString = formatTimestamp(item?.createdAt).toLocaleString()
        return {
          ...item, createdAt: formatLocaleString
        }});
      let ws = isContact ? XLSX.utils.json_to_sheet(dataContact) : XLSX.utils.json_to_sheet(data);
      const keys = Object.keys(ws);
      const keysWithNumber1 = keys.filter((key) => key.includes('1'));
      const columnSize = 20;
      const rowHeight = 40;
      const objectsForWith = keysWithNumber1.map(() => {
        return { width: columnSize };
      });
      ws['!cols'] = [...objectsForWith];
      ws['!rows'] = [{ hpx: rowHeight }];
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `EbbinerData_${dataRequiredSelected}`);
      const fileName = `dataEbbiner_${dataRequiredSelected}.xlsx`;
      XLSX.writeFile(wb, fileName);
    }
  } catch(err) {
    console.warn(err);
  }
};
