export const filterForCourse = ({
  courseToFilter,
  setDataSortedByCourse,
  listOfCourses,
  reportedQuestionData,
  unreviewedQuestionData,
}) => {
  setDataSortedByCourse([]);
  if (courseToFilter !== 'all') {
    const course = listOfCourses?.find((course) => course?.value === courseToFilter)?.name;
    reportedQuestionData &&
      setDataSortedByCourse(
        reportedQuestionData?.filter((question) => question?.course === course),
      );
    unreviewedQuestionData &&
      setDataSortedByCourse(
        unreviewedQuestionData?.filter((question) => question?.course === course),
      );
  } else {
    reportedQuestionData && setDataSortedByCourse(reportedQuestionData);
    unreviewedQuestionData && setDataSortedByCourse(unreviewedQuestionData);
  }
};
