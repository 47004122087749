import { useState, useEffect } from "react";
import {
  QuestionInputContainer,
  TextQuestionStyled,
} from "./styles/sQuestionInput";

import { TitleOfQuestion } from "./styles/sMainCheckQuestion";
// import Latex from "react-latex-next";

import { Latex } from "../../latex/Latex";
import { Text } from "../../../styles/textGeneral";
import { useTranslation } from "react-i18next";

//Import images
import watermark from "../../../icons/ebbiner-marca-de-agua.svg";

export default function QuestionOutput({
                                         question,
                                         keys = [],
                                         UrlOfImage = "",
                                         course = null,
                                         captureQuestion = false,
                                         setKeyLetter,
                                       }) {
  const [t] = useTranslation("main");
  const [randomedKeys] = useState(
      captureQuestion ? keys.slice(0, 3).sort(() => Math.random() - 0.5) : null
  );

  useEffect(() => {
    if (captureQuestion && randomedKeys) {
      switch (randomedKeys.indexOf(keys[0])) {
        case 0:
          setKeyLetter("a");
          break;
        case 1:
          setKeyLetter("b");
          break;
        case 2:
          setKeyLetter("c");
          break;
      }
    }
  }, [keys]);

  return (
      <QuestionInputContainer>
        {captureQuestion && <img src={watermark} />}
        {captureQuestion && course && (
            <TitleOfQuestion className="course">
              {t(`courses.${course}`)}
            </TitleOfQuestion>
        )}
        <TextQuestionStyled>
          {question ? (
              <Latex>{question}</Latex>
          ) : (
              <Text>{"No hay pregunta"}</Text>
          )}
        </TextQuestionStyled>
        {UrlOfImage && (
            <img
                loading="lazy"
                className="questionImage"
                src={UrlOfImage}
                alt={"imagen de la pregunta"}
            />
        )}
        {(captureQuestion ? randomedKeys : keys)?.map((item, index) => {
          let keyLetter = null;
          switch (index) {
            case 0:
              keyLetter = "a";
              break;
            case 1:
              keyLetter = "b";
              break;
            case 2:
              keyLetter = "c";
              break;
            case 3:
              keyLetter = "d";
              break;
            case 4:
              keyLetter = "e";
              break;
            default:
              keyLetter = null;
          }
          if (keyLetter !== null) {
            return (
                <div className="questionContainer" key={item.concat(index)}>
                  <label htmlFor={item.concat(question)}>
                    <span className="keyLetterQuestion">{keyLetter}</span>
                    <div className="question">
                      {item.startsWith("https") ? (
                          <img loading="lazy" src={item} alt={item} />
                      ) : (
                          <>
                            {index === 0 ? (
                                <Latex>
                                  {!captureQuestion
                                      ? `\\textcolor{green}{${item}}`
                                      : item}
                                </Latex>
                            ) : (
                                <Latex>
                                  {!captureQuestion
                                      ? `\\textcolor{red}{${item}}`
                                      : item}
                                </Latex>
                            )}
                          </>
                      )}
                    </div>
                  </label>
                </div>
            );
          }
          return false;
        })}
        {captureQuestion && <p className="linkEbbiner">www.ebbiner.com</p>}
      </QuestionInputContainer>
  );
}
