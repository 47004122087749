import React from 'react';
import { Helmet } from 'react-helmet';
import MainStudentsData from '../components/students/MainStudentsData';

export default function StudentsData() {
  return (
    <>
      <Helmet>
        <title>Datos de estudiantes | Ebbiner</title>
      </Helmet>
      <MainStudentsData />
    </>
  );
}
