export const convertTimestampToDate = ({
  timestampSeconds,
  timestampNanoseconds,
}) => {
  const fecha = new Date(
    timestampSeconds * 1000 + Math.floor(timestampNanoseconds / 1e6)
  );

  const year = fecha.getUTCFullYear();
  const month = String(fecha.getUTCMonth() + 1).padStart(2, "0");
  const day = String(fecha.getUTCDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
