//Import components for the routes
import Home from "./pages/Home";
import Lives from "./pages/Lives";
import WriteQuestion from "./pages/WriteQuestion";
import CheckQuestion from "./pages/CheckQuestion";
import UploadVideo from "./pages/UploadVideo";
import WriteTopic from "./pages/WriteTopic";
import EditQuestion from "./pages/EditQuestion";
import StudentsData from "./pages/StudentsData";
import { EditQuestionReported } from "./components/examns/editQuestion/EditQuestionReported";
import { Subscriptions } from "./pages/Subscriptions";

export const adminRoutes = [
  {
    path: "/question/:idQuestion",
    element: <CheckQuestion />,
  },
  {
    path: "/edit-question/:idQuestion",
    element: <EditQuestionReported />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/upload-video",
    element: <UploadVideo />,
  },
  {
    path: "/write-question",
    element: <WriteQuestion />,
  },
  {
    path: "/check-questions",
    element: <CheckQuestion />,
  },
  {
    path: "/edit-question",
    element: <EditQuestion />,
  },
  {
    path: "/lives",
    element: <Lives />,
  },
  {
    path: "/write-subtopics",
    element: <WriteTopic />,
  },
  {
    path: "/subscriptions",
    element: <Subscriptions />,
  },
];

export const marketingRoutes = [
  {
    path: "/data-students",
    element: <StudentsData />,
  },
];
