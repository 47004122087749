import styled, { css } from "styled-components";

export const TagContainerCheck = styled.div`
  padding: 12px 15px;
  font-family: ${(props) => props.theme.textFont};
  font-weight: ${(props) => props.theme.weight.medium};
  font-size: 0.875em;
  border-radius: 50px;
  cursor: default;

  .svg-icon {
    width: 1.9em;
    height: 1.9em;
    transform: scale(1.5);
    transition: all .2s;
  }

  ${(props) =>
    props.type == "no" &&
    css`
      background: ${props => props.theme.red300};
      cursor: pointer;

      .svg-icon {
        fill: ${props => props.theme.red500};

        &:hover {
          fill: ${props => props.theme.red700};
        }
      }
    `}

  ${(props) =>
    props.type === "ok" &&
    css`
      background: ${props => props.theme.green300};
      cursor: pointer;

      .svg-icon {
        fill: ${props => props.theme.green500};

        &:hover {
          fill: ${props => props.theme.green700};
        }
      }
    `}



  .div {
    cursor: pointer;
    margin: 5px;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`