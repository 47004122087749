import { object, string } from "yup";

export const emailValidator = {
  email: string()
    .email("Ingrese un correo valido")
    .matches(/^[a-z0-9._-]+@gmail\.com$/, "Ingrese correo válido de @gmail.com")
    .required("El campo es obligatorio"),
};

export const formAddSubscriber = object({
  username: string()
    .matches(
      /^[a-zA-Z0-9À-ÿ\u00f1\u00d1\u00E0-\u00FC ]+$/,
      "Ingrese un nombre válido"
    )
    .required("El campo es obligatorio"),
  dateLimit: string().required("El campo es obligatorio"),
  ...emailValidator,
});

export const formMainSearch = object({
  ...emailValidator,
});
